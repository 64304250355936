import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'


export default function ScheduleConsultation() {
  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: 'rgb(248, 249, 250)',
          paddingTop: '3rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
        }}
      >
        <Container>
          <Row style={{ marginBottom: '3rem' }}>
            <Col>
              <h2>Schedule a Consultation</h2>
              <p className="mb-4">
                Each person will be given an initial consultation to discuss symptoms and medical history. Keep in mind you need blood labs drawn and it is helpful to have the results prior to your first consultation. If you are paying cash for your labs or have a high deductible you can order at my cost after scheduling your initial consultation. You will have your second consultation after 30 days.
              </p>
              <a
                href="https://fixaflash.practicebetter.io/#/5c5e06b1627d791278033363/bookings?r=5c638d55627d79029cd892d4&step=services"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary" className="mr-4">Schedule now&nbsp;&nbsp;<sup><i style={{ fontSize: '.65rem' }} className="fas fa-external-link-alt"></i></sup></Button>
              </a>
              {/* <a
                href="https://fixaflash.practicebetter.io/#/5c5e06b1627d791278033363/bookings?r=5c638d55627d79029cd892d4&step=services"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary">Order labs&nbsp;&nbsp;<sup><i style={{ fontSize: '.65rem' }} className="fas fa-external-link-alt"></i></sup></Button>
              </a> */}
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}
